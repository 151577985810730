import { createBrowserRouter } from 'react-router-dom';
import Loadable from '../components/elements/Loadable/Loadable';
import { lazy } from 'react';

const MenuLayout = Loadable(lazy(() => import('../components/layouts/MenuLayout')));

const Cart = Loadable(lazy(() => import('../pages/Cart')));
const Item = Loadable(lazy(() => import('../pages/Item')));
const Outlet = Loadable(lazy(() => import('../pages/Outlet')));
const Outlets = Loadable(lazy(() => import('../pages/Outlets')));
const Portal = Loadable(lazy(() => import('../pages/Portal')));
const Splash = Loadable(lazy(() => import('../pages/Splash')));

const router = createBrowserRouter([
  { path: '/', element: <Splash /> },
  {
    path: '/cart',
    element: <MenuLayout />,
    children: [{ path: '/cart', element: <Cart /> }],
  },
  { path: '/portal', element: <Portal /> },
  {
    path: '/outlets',
    element: <MenuLayout />,
    children: [
      { path: '/outlets', element: <Outlets /> },
      { path: '/outlets/:id', element: <Outlet /> },
      { path: '/outlets/:id/item/:itemId', element: <Item /> },
    ],
  },
]);

export default router;
