import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TenantState {
  code: string;
  outlet: Outlet;
  outlets: Outlet[];
  categories: Category[];
  menus: MenuItem[];
}

const initialOutlet: Outlet = {
  alamat: '',
  id: 0,
  kecamatan: '',
  kodePos: '',
  kota: '',
  nama: '',
  tagLine: '',
  foto: '',
  telp: '',
};

const initialState: TenantState = {
  categories: [],
  code: '',
  outlet: initialOutlet,
  outlets: [],
  menus: [],
};

const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    clearOutlet: (state) => {
      state.outlet = initialOutlet;
    },
    setCategories: (state, { payload }: PayloadAction<Category[]>) => {
      state.categories = payload;
    },
    setOutlet: (state, { payload }: PayloadAction<Outlet>) => {
      state.outlet = payload;
    },
    setOutlets: (state, { payload }: PayloadAction<Outlet[]>) => {
      state.outlets = payload;
    },
    setTenantCode: (state, { payload }: PayloadAction<string>) => {
      state.code = payload;
    },
    setMenus: (state, { payload }: PayloadAction<MenuItem[]>) => {
      state.menus = payload;
    },
  },
});

export const {
  clearOutlet,
  setCategories,
  setOutlet,
  setOutlets,
  setTenantCode,
  setMenus,
} = tenantSlice.actions;

export default tenantSlice.reducer;
