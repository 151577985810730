import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TableState {
  tableNo: number;
  orderType: OrderType,
  cart: OrderItem[],
}

const initialState: TableState = {
  tableNo: 0,
  orderType: 'dine in',
  cart: [],
};

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setTableNo: (state, { payload }: PayloadAction<number>) => {
      state.tableNo = payload;
    },
    setOrderType: (state, { payload }: PayloadAction<OrderType>) => {
      state.orderType = payload;
    },
    addToCart: (state, { payload }: PayloadAction<OrderItem>) => {
      const id = `${state.tableNo}${(new Date()).getTime()}`
      state.cart = [ ...state.cart, { ...payload, id } ];
    },
    removeFromCart: (state, { payload }: PayloadAction<OrderItem>) => {
      const newCart = [];
      for (let i = 0; i < state.cart.length; i++) {
        if (state.cart[i].id !== payload.id) {
          newCart.push({ ...state.cart[i] });
        }
      }

      state.cart = newCart;
    },
    updateCartItem: (state, { payload }: PayloadAction<OrderItem>) => {
      for (let i = 0; i < state.cart.length; i++) {
        if (state.cart[i].id === payload.id) {
          state.cart[i] = payload;

          const totalAddOn = payload.addOns.reduce<MenuAddOnItem[]>((prev, v) => {
            if (v.items == null) return prev;
            return [...prev, ...v.items];
          }, []).reduce((prev, v) => prev + v.harga, 0);

          state.cart[i].hargaTotal = (payload.hargaJual + totalAddOn) * payload.qty;
        }
      }
    },
  },
});

export const { setTableNo, setOrderType, addToCart, removeFromCart, updateCartItem } = tableSlice.actions;
export default tableSlice.reducer;
