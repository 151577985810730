import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UIToast {
  id?: string;
  color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
  message: string,
}

interface UIState {
  sidebarCollapse: boolean;
  loading: boolean,
  numOfReq: number,
  toast: UIToast,
}

const initialState: UIState = {
  sidebarCollapse: false,
  loading: false,
  numOfReq: 0,
  toast: { color: 'inherit', message: '' },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      let numOfReq = state.numOfReq;
      if (payload) {
        numOfReq += 1;
      } else {
        numOfReq -= 1;
      }

      if (numOfReq < 0) {
        numOfReq = 0;
      }

      const loading = numOfReq > 0;
      return { ...state, loading, numOfReq };
    },
    setSidebarCollapse: (state, { payload }: PayloadAction<boolean>) => {
      state.sidebarCollapse = payload;
    },
    showToast: (state, { payload }: PayloadAction<UIToast>) => {
      state.toast.id = (new Date()).toString();
      state.toast.color = payload.color;
      state.toast.message = payload.message;
    },
  },
});

export const { setSidebarCollapse, setLoading, showToast } = uiSlice.actions;
export default uiSlice.reducer;
