import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import router from './routes';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './stores';

const theme = createTheme({
  palette: {
    primary: { main: '#003724' },
    secondary: { main: '#F1A208' },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: 8,
        },
      },
    },
    MuiButton: {
      defaultProps:{
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '8px 22px',
          borderRadius: 12,
        },
      },
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <RouterProvider router={router} />
        {/* <AppToast /> */}
      </Provider>
    </ThemeProvider>
  );
};

export default App;
